//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import pNav from '~/components/cmscontent/p-nav/index.vue'
import skeletonScreen from '~/components/cmscontent/skeletonScreen/index.vue'

export default {
  components: {
    pNav,
    skeletonScreen,
  },
  async asyncData({ app }) {
    const asyncData = {
      org_id: app.store.state.org_id,
    }
    if (!app.router.history.current.query.classification) {
      // 获取首页列表
      await new Promise((resolve) => {
        app.$api
          .getHomeList({
            org_id: app.store.state.org_id,
            page_num: 1,
            is_release: 1,
            page_size: 12,
          })
          .then((res) => {
            // console.log(res, '首页list')
            asyncData.homeListData = res.code === 0 ? res.data : {}
            resolve()
          })
      })
    } else {
      // 获取分类列表
      await new Promise((resolve) => {
        app.$api
          .getContentList({
            org_id: app.store.state.org_id,
            page_num: 1,
            is_release: 1,
            page_size: 12,
            classification: app.router.history.current.query.classification,
          })
          .then((res) => {
            console.log(res, '首页list')
            asyncData.homeListData = res.code === 0 ? res.data : {}
            resolve()
          })
      })
    }

    // 获取首页详情信息，包含各种设置项
    await new Promise((resolve) => {
      let params = {
        org_id: app.store.state.org_id,
        is_release: 1,
      }
      if (app.router.history.current.query.classification > 0) {
        params.classification = app.router.history.current.query.classification
      }
      app.$api.getHomeInfo(params).then((res) => {
        asyncData.homeSettingInfo = res.code === 0 ? res.data : {}
        resolve()
      })
    })
    await new Promise((resolve) => {
      app.$api
        .getHomeInfo({
          org_id: app.store.state.org_id,
          is_release: 2,
        })
        .then((res) => {
          //console.log('audit_status', res.data)
          if (res.data.is_domain == 1) {
            if (res.data.domain && res.data.domain.includes('https')) {
              asyncData.host = res.data.domain.replace('https://', '')
            } else {
              asyncData.host = res.data.domain
            }
          } else {
            asyncData.host = 'app.beschannels.com'
          }

          resolve()
        })
    })
    // 首页分类
    await new Promise((resolve) => {
      app.$api
        .getHomeNavList({
          org_id: app.store.state.org_id,
        })
        .then((res) => {
          var menuList = [],
            showNum = 7
          if (res.code === 0) {
            // 往里面补充 首页
            menuList = [
              {
                id: -1,
                label: '首页',
              },
              ...res.data,
            ]
          }
          //console.log(menuList)
          if (menuList.length > showNum) {
            let menuListCopy = [
              ...menuList.slice(0, showNum),
              {
                id: '-2',
                label: '更多',
                children: [...menuList.slice(showNum)],
              },
            ]
            menuList = [...menuListCopy]
          }
          asyncData.menuList = menuList
          console.log(menuList)
          resolve()
        })
    })
    return asyncData
  },
  data() {
    return {
      page: 1,
      org_id: '',
      showNoMoreText: false, // 是否需要展示 没有底部更多数据, 两种情况，筛选的和首页的，获取到数据为0并且page不为1时展示
      postVal: { item: {}, name: '' },
      customer_id: '',
      is_release: 2,
      custom_classification: null, //搜索的时候全局搜索，在分类下是要重置成-1
      skeletonflag: false,
      pageSize: 12,
    }
  },
  head() {
    let baiduUrl = this.httpString(this.homeSettingInfo.seo_track)
    let baiScript = []
    if (baiduUrl) {
      baiScript = [{ src: baiduUrl[0] }]
    }
    return {
      title:
        this.homeSettingInfo.is_bower_title && this.homeSettingInfo.bower_title
          ? this.homeSettingInfo.bower_title
          : '',
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href:
            this.homeSettingInfo.is_bower_title &&
            this.homeSettingInfo.ico_image
              ? this.homeSettingInfo.ico_image
              : '',
        },
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.homeSettingInfo.seo_description,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.homeSettingInfo.seo_keywords,
        },
      ],
      script: baiScript,
    }
  },
  computed: {
    imgUrl() {
      return this.prefixImageUrl(process.env.NODE_ENV)
    },
  },
  mounted() {
    // this.insertScriptCode(this.homeSettingInfo.seo_track)
    this.postVal = JSON.parse(sessionStorage.getItem('postVal'))
    let params = {
      // parent_page_id: this.pId,
      page_id: -1, // 必传 页面id
      page_first_title: '内容首页', // 可传 页面一级标题
      module_type: 'cms',
      target_type: 'content',
      system_type: 'renew',
      // visit_props: { key: 123 },
      // ...getUtmJson(window.location, 'link'),
    }
    this.myCollect.$visit(params)
  },

  // beforeMount() {
  //   window.addEventListener(
  //     'scroll',
  //     this.debounce(this.handleScroll, 500),
  //     true
  //   )
  // },
  // beforeDestroy() {
  //   window.removeEventListener(
  //     'scroll',
  //     this.debounce(this.handleScroll, 500),
  //     true
  //   )
  // },
  created() {
    if (process.browser) {
      window.addEventListener(
        'message',
        function (e) {
          //console.log('12345', e.data)
          if (e.data.customer_id) {
            localStorage.setItem('customer_id', e.data.customer_id)
          }
        },
        false
      )
      this.customer_id = localStorage.getItem('customer_id')
        ? localStorage.getItem('customer_id')
        : ''
      this.myCollect = new window.Collect({
        org_id: this.$store.state.org_id,
        customer_id: this.customer_id,
        module_type: 'cms',
        // baseUrl: this.$trackUrl,
        target_type: 'content',
        system_type: 'renew',
      })
      this.myCollect.init({
        page_view_auto: false,
        element_click_auto: false,
        events: ['BUTTON', 'input[type="submit"]', 'IMG', 'A'],
      })
    }
  },
  methods: {
    // eventClick() {
    //   this.myCollect.$eventClick({ event_props: { key: 123 } })
    //   location.href = 'https://www.baidu.com/'
    // },
    onGetMenu(item, type, searchName) {
      this.skeletonflag = true
      this.$refs.pNav.searchVal = searchName
      if (this.postVal) {
        sessionStorage.removeItem('postVal')
      }
      document.documentElement.scrollTop = 0 // 页面回滚顶部
      this.showNoMoreText = false
      this.page = 1 // 重置Page为1
      let { page } = this
      if (item.id === -1 && type === 'click_menu' && !searchName) {
        this.is_release = 2
        // 点击的是首页，重新加载首页列表的第一屏
        let wechat = this.$route.query.wechat ? this.$route.query.wechat : ''
        let wechatFlag = wechat ? `?wechat=${wechat}` : ''
        location.href = `${window.location.origin}${wechatFlag}`
      } else {
        this.is_release = 0
        // 点击的是分类
        let name = ''
        if (searchName) {
          name = searchName
          this.custom_classification = -1
          this.$refs.pNav.currentPage1 = 1
          this.$api
            .getContentList({
              org_id: this.$store.state.org_id,
              classification: this.custom_classification == '-1' ? -1 : item.id,
              name: name,
              page_num: page,
              page_size: 12,
            })
            .then((res) => {
              if (res.code == 0) {
                this.homeListData = {
                  heightlight: [],
                  list: res.data.list,
                }
                this.$set(this.homeListData, 'total', res.data.total)
                this.skeletonflag = false
                if (
                  this.homeListData.list &&
                  this.homeListData.list.length < 8 &&
                  this.homeListData.list.length > 0
                ) {
                  this.showNoMoreText = true
                  // alert(this.showNoMoreText)
                }
              }
            })
        } else {
          let wechat = this.$route.query.wechat ? this.$route.query.wechat : ''
          let wechatFlag = wechat ? `&wechat=${wechat}` : ''
          location.href = `${window.location.origin}?classification=${item.id}${wechatFlag}`
        }
      }
    },
    //翻页
    handleCurrentChange(val) {
      this.skeletonflag = true
      document.documentElement.scrollTop = 0 // 页面回滚顶部
      let type = this.$refs.pNav.currentType,
        currentInfo = this.$refs.pNav.currentClassification
      this.page = val
      if (
        currentInfo.id === -1 &&
        type === 'click_menu' &&
        !this.$refs.pNav.searchVal &&
        !this.$route.query.classification
      ) {
        // 滚动到底部需要判断是在首页，还是在分类
        this.pageSize = 12
        this.$api
          .getHomeList({
            org_id: this.$store.state.org_id,
            page_num: this.page,
            page_size: this.pageSize,
            is_release: 1,
          })
          .then((res) => {
            if (res.code == 0 && res.data.list) {
              this.homeListData.list = [...res.data.list]
              this.homeListData.total = res.data.total
              this.skeletonflag = false
              if (this.page != 1) {
                this.homeListData.heightlight = []
              } else {
                this.homeListData.heightlight = res.data.heightlight
              }

              if (this.page != 1 && res.data.list.length == 0) {
                this.showNoMoreText = true
              }
              if (this.homeListData.list.length == 0) {
                this.showNoMoreText = false
              }
            }
          })
      } else {
        // 点击的是分类
        let name = this.$refs.pNav.searchVal
        this.$api
          .getContentList({
            org_id: this.$store.state.org_id,
            classification: this.$route.query.classification,
            name: name,
            page_num: this.page,
            page_size: 12,
          })
          .then((res) => {
            //console.log(res)
            if (res.code == 0) {
              this.homeListData = {
                heightlight: [],
                list: [...res.data.list],
              }
              this.homeListData.total = res.data.total
              this.skeletonflag = false

              if (this.homeListData.list.length === 0) {
                this.showNoMoreText = false
              } else {
                if (this.page !== 1 && res.data.list.length === 0) {
                  this.showNoMoreText = true
                }
              }
            }
          })
      }
    },
    handleScroll() {
      //变量scrollTop是滚动条滚动时，滚动条上端距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop

      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight

      //变量scrollHeight是滚动条的总高度（当前可滚动的页面的总高度）
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      let type = this.$refs.pNav.currentType,
        currentInfo = this.$refs.pNav.currentClassification
      //滚动条到底部
      if (scrollTop + windowHeight >= scrollHeight - 20) {
        this.page++
        if (
          currentInfo.id === -1 &&
          type === 'click_menu' &&
          !this.$refs.pNav.searchVal &&
          !this.$route.query.classification
        ) {
          // 滚动到底部需要判断是在首页，还是在分类
          this.$api
            .getHomeList({
              org_id: this.$store.state.org_id,
              page_num: this.page,
              is_release: 1,
            })
            .then((res) => {
              if (res.code == 0 && res.data.list) {
                this.homeListData.list = [
                  ...this.homeListData.list,
                  ...res.data.list,
                ]
                this.homeListData.total = res.data.total
                if (this.page != 1 && res.data.list.length == 0) {
                  this.showNoMoreText = true
                }
                if (this.homeListData.list.length == 0) {
                  this.showNoMoreText = false
                }
              }
            })
        } else {
          // 点击的是分类
          let name = this.$refs.pNav.searchVal
          this.$api
            .getContentList({
              org_id: this.$store.state.org_id,
              classification: this.$route.query.classification,
              name: name,
              page_num: this.page,
              page_size: 12,
            })
            .then((res) => {
              //console.log(res)
              if (res.code == 0) {
                this.homeListData = {
                  heightlight: [],
                  list: [...this.homeListData.list, ...res.data.list],
                }
                this.homeListData.total = res.data.total
                if (this.homeListData.list.length === 0) {
                  this.showNoMoreText = false
                } else {
                  if (this.page !== 1 && res.data.list.length === 0) {
                    this.showNoMoreText = true
                  }
                }
              }
            })
        }
      }
    },
    // jump2Link(info) {
    //   info && info.link ? window.open(info.link, 'target') : null
    // },
    jump2Link(info) {
      let link = ''
      if (info.link_type == 1) {
        link = info.link + '&pType=contenthp&pId=-1&release=1'
      } else {
        link = info.link
      }
      return link
    },
    debounce(fn, delay) {
      let timer = null //借助闭包
      return function () {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(fn, delay) // 简化写法
      }
    },
  },
}
