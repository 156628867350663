var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-cmscontent-wrap",style:({
    paddingBottom:
      _vm.homeSettingInfo && _vm.homeSettingInfo.is_footer === 1 ? '0rem' : '0.1rem',
  })},[(_vm.skeletonflag)?_c('skeletonScreen'):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"height":"1.08rem","width":"100%"}}),_vm._v(" "),(
      _vm.homeSettingInfo &&
      _vm.homeSettingInfo.is_carouselmap === 1 &&
      _vm.homeSettingInfo.carouselmap.length > 0 &&
      _vm.is_release
    )?_c('div',{staticClass:"p-banner-wrap p-content-width margin-center"},[_c('el-carousel',{staticClass:"el-banner",attrs:{"interval":_vm.homeSettingInfo ? _vm.homeSettingInfo.map_time * 1000 : 1000,"indicator-position":_vm.homeSettingInfo.carouselmap.length == 1 ? 'none' : '',"trigger":"click","arrow":_vm.homeSettingInfo.carouselmap.length == 1 ? 'never' : 'always'}},_vm._l((_vm.homeSettingInfo.carouselmap),function(item,index){return _c('el-carousel-item',{key:item.image + "" + index},[_c('a',{attrs:{"href":item.link ? _vm.jump2Link(item) : 'javascript:void(0);',"target":item.link ? '_blank' : ''}},[_c('img',{staticClass:"wp100 hp100 pointer",attrs:{"src":item.image}})])])}),1)],1):_vm._e(),_vm._v(" "),_c('p-nav',{ref:"pNav",attrs:{"menu-list":_vm.menuList,"home-setting-info":_vm.homeSettingInfo,"home-list-data":_vm.homeListData,"show-no-more-text":_vm.showNoMoreText,"is-statistics":1,"page-size":_vm.pageSize,"host":_vm.host},on:{"onGetMenu":_vm.onGetMenu,"handleCurrentChange":_vm.handleCurrentChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }